<template>
  <div>
    <div class="invoice-wrapper" :id="`invoice-wrapper_${invoice.id}`">
      <div class="invoice-header">
        <div
          class="invoice-logo d-flex flex-column align-items-center justify-content-center"
        >
          <img src="/media/logos/logo.png" class="h-75px" />
          <h4>Invoice</h4>
        </div>
      </div>
      <div class="border-invoice"></div>
      <div class="customer-info">
        <strong>Customer Information</strong>
        <div class="d-flex align-items-center" style="width: 80%">
          <p><bold>Name</bold>: {{ invoice.customerName }}</p>
          <p style="margin-left: auto">
            <bold>Invoice number </bold>: # {{ invoice.invoiceNo }}
          </p>
        </div>
        <div class="d-flex align-items-center" style="width: 80%">
          <p><bold>phone number</bold>: {{ invoice.customerPhoneNumber }}</p>
          <p style="margin-left: auto">
            <bold>Invoice date </bold>: {{ invoice.createdAt.slice(0, 10) }}
          </p>
        </div>
        <div class="d-flex align-items-center" style="width: 80%">
          <p>
            <bold>Payment Status</bold>:
            {{ invoice.status ? 'Paid' : 'Not Paid' }}
          </p>
        </div>
      </div>
      <!--begin::customer statment -->
      <div class="service" v-if="invoice.customerStatement.length > 0">
        <h4 class="mx-3">Service</h4>
        <div class="desc d-flex justify-content-between align-content-center">
          <p>Description</p>
          <p>Unit Price</p>
        </div>

        <div
          v-for="statment in invoice.customerStatement"
          :key="statment.id"
          class="service-details"
        >
          <div
            v-if="statment.discount > 0"
            class="d-flex justify-content-between align-content-center"
          >
            <p>Price Before Discount</p>
            <p style="text-decoration: line-through">
              {{ statment.priceBeforeDiscount }}
            </p>
          </div>
          <div class="d-flex justify-content-between align-content-center">
            <p>{{ statment.serviceType }}</p>
            <p>{{ statment.amount }}</p>
          </div>
        </div>
      </div>
      <!--end::customer statment -->

      <!--begin::customer statment service-->
      <div class="service" v-if="invoice.customerServiceSatatement.length > 0">
        <h4 class="mx-3">Customer Service Statement</h4>
        <div class="desc d-flex justify-content-between align-content-center">
          <p>Description</p>
          <p>Unit Price</p>
        </div>

        <div
          v-for="customerService in invoice.customerServiceSatatement"
          :key="customerService.id"
          class="service-details"
        >
          <div
            v-if="customerService.discount > 0"
            class="d-flex justify-content-between align-content-center"
          >
            <p>Price Before Discount</p>
            <p style="text-decoration: line-through">
              {{ customerService.priceBeforeDiscount }}
            </p>
          </div>
          <div class="d-flex justify-content-between align-content-center">
            <p>{{ customerService.serviceTypeData.nameEn }}</p>
            <p>{{ customerService.amount }}</p>
          </div>
          <div class="d-flex justify-content-between align-content-center">
            <p>Payment Method</p>
            <p>Online</p>
          </div>
        </div>
      </div>
      <!--end::customer statment service-->
      <!--begin::total -->
      <div class="total-invoice">
        <div class="d-flex justify-content-between align-content-center">
          <p class="sub-total-invoice">Sub Total</p>
          <p>{{ invoice.totalPrice }}</p>
        </div>
      </div>
      <!--end::total -->

      <!---begin::sign-->
      <div
        class="sign-wrapper d-flex justify-content-between align-items-center m-4"
      >
        <div class="sign-box">
          <div class="sign-title d-flex flex-column">
            <span>توقيع المستلم</span>
            <span>Res.sign</span>
          </div>
        </div>
        <div class="sign-box">
          <div class="sign-title d-flex flex-column">
            <span>المحاسب </span>
            <span>accountant</span>
          </div>
        </div>
      </div>
      <!---end::sign-->
    </div>
    <img @click="convertToPdf" src="/media/icons/duotune/files/fil017.svg" />
  </div>
</template>

<script setup>
  import html2pdf from 'html2pdf.js';
  import { onMounted, onUnmounted, defineProps } from 'vue';
  import { InvoiceObject } from '@/store/modules/finance/InvoiceModule';

  const props = defineProps({
    invoice: InvoiceObject,
  });
  console.log(props.invoice);
  onMounted(() => {
    const element = document.getElementById(
      `invoice-wrapper_${props.invoice.id}`
    );
    document.documentElement.appendChild(element);
  });
  onUnmounted(() => {
    const element = document.getElementById(
      `invoice-wrapper_${props.invoice.id}`
    );
    if (element) {
      element.remove();
    }
  });

  const convertToPdf = async () => {
    const element = document.getElementById(
      `invoice-wrapper_${props.invoice.id}`
    );
    element.style.display = 'block';

    let opt = {
      filename: 'invoice.pdf',
      html2canvas: { scale: 1 },
      jsPDF: { format: 'a4', orientation: 'portrait' },
      image: { type: 'jpeg', quality: 0.98 },
    };

    html2pdf()
      .from(element)
      .set(opt)
      .outputPdf('blob')
      .then((pdfBlob) => {
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, '_blank');
        setTimeout(() => {
          element.style.display = 'none';
        }, 0);
      });
  };
</script>

<style lang="scss">
  .invoice-wrapper {
    display: none;
    background: #fff;
    .invoice-logo {
      h4 {
        font-size: 24px;
      }
    }
    .border-invoice {
      margin: 20px 0;
      border: 3px solid #ccc;
    }
    .customer-info {
      margin: 0 30px;
      margin-bottom: 50px;
      border-bottom: 2px dashed #ccc;
      strong {
        display: inline-block;
        margin-bottom: 15px;
        margin-top: 10px;
        font-size: 20px;
      }
      bold {
        font-weight: bold;
        display: inline-block;
        margin: 5px 0;
      }
      p {
        color: #000;
      }
    }
    .service {
      .desc {
        padding: 10px;
        background: #4a4a4a;
        color: #fff;
        margin: 20px 0;
      }
      .service-details {
        border-bottom: 1px dashed #ccc;
        margin: 15px;
      }
    }

    .total-invoice {
      margin: 20px;
      .sub-total-invoice {
        font-weight: bold;
      }
    }

    .sign-wrapper {
      border-top: 3px solid #ccc;
      padding-top: 30px;
    }

    .sign-box {
      width: 300px;
      border: 1.5px solid #555;
    }
    .sign-title {
      background: #aaa;
      height: 100%;
      width: 30%;
      text-align: center;
      border-right: 1px solid #555;
      span {
        white-space: nowrap;
      }
    }
  }
</style>
